<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <div class="border rounded p-4 mb-3">
                <b-row>
                    <b-col sm="4">
                        <ValidationProvider name="registration_semester_id" rules="required" v-slot="{ errors }">
                            <b-form-group :label="$t('registration_semester')">
                                <semesters-selectbox v-model="formData.registration_semester_id"
                                                     :disabled="activeRole"
                                    :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="registration_date" rules="required" v-slot="{ errors }">
                            <b-form-group :label="$t('registration_date')">
                                <selectDate v-model="formData.registration_date"
                                            :validationError="errors[0]" v-if="!activeRole"/>
                                <b-form-input
                                    v-model="formData.registration_date"
                                    :disabled="activeRole"
                                    v-else
                                ></b-form-input>
                            </b-form-group>
                        </ValidationProvider>

                        <ValidationProvider name="registration_type" rules="required" v-slot="{ errors }">
                            <b-form-group :label="$t('registration_type')">
                                <parameter-selectbox code="registration_types" :disabled="activeRole" v-model="formData.registration_type">
                                </parameter-selectbox>
                            </b-form-group>
                        </ValidationProvider>

                        <!-- <ValidationProvider name="scholarship_rate" rules="required" v-slot="{ errors }">
                            <b-form-group :label="$t('scholarship_rate')">
                                <scholarship-rate-selectbox :validateError="errors[0]" v-model="formData.scholarship_rate">
                                </scholarship-rate-selectbox>
                            </b-form-group>
                        </ValidationProvider> -->

                        <ValidationProvider name="yoksis_unit_id" rules="" v-slot="{ errors }">
                            <b-form-group :label="$t('yoksis_unit_id')">
                                <b-form-input v-model="formData.yoksis_unit_id"
                                              :disabled="activeRole"
                                    :state="errors[0] ? false : null"></b-form-input>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>

                    </b-col>
                    <b-col sm="4">
                        <ValidationProvider name="prep_status" rules="required" v-slot="{ errors }">
                            <b-form-group :label="$t('prep_status')">
                                <parameter-selectbox v-model="formData.prep_status"  code="prep_statuses"
                                    :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>

                        <ValidationProvider name="student_status" rules="required" v-slot="{ errors }">
                            <b-form-group :label="$t('student_status')">
                                <parameter-selectbox v-model="formData.student_status" :disabled="activeRole"  code="student_statuses"
                                    :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="semester_status" v-slot="{ errors }">
                            <b-form-group :label="$t('semester_status')">
                                <parameter-selectbox v-model="formData.semester_status"  code="semester_statuses"
                                    :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>

                        <ValidationProvider name="advisor_id" rules="" v-slot="{ errors }">
                            <b-form-group :label="$t('advisor')">
                                <staff-auto-complete v-model="formData.advisor_id" :disabled="activeRole" :valueObject="advisorObject"
                                    :validate-error="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col sm="4">
                        <ValidationProvider name="class" rules="required" v-slot="{ errors }">
                            <b-form-group :label="$t('class')">
                                <parameter-selectbox
                                    v-model="formData.class"
                                    code="classes"
                                    :validateError="errors[0]"
                                    :withoutItems="['YL','D'].includes(studentProgram.program_level) ? '1,2,3,4,5,6,7,15' : ''" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="curriculum_id" rules="" v-slot="{ errors }">
                            <b-form-group :label="$t('curriculum')">
                                <curriculum-selectbox v-model="formData.curriculum_id"
                                                      :disabled="activeRole"
                                    :program_code="studentProgram.program_code" :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="irregular" rules="" v-slot="{ errors }">
                            <b-form-group :label="$t('irregular')">
                                <yes-no-selectbox v-model="formData.irregular"  :disabled="activeRole" :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                </b-row>
            </div>
            <b-row>
                <b-col md="4" class="flex-column d-flex">
                    <div class="mb-2">
                        <h6 class="mb-0">{{ $t('graduation_information') }}</h6>
                    </div>
                    <div class="border rounded equal-height p-4 mb-4">
                        <ValidationProvider name="graduation_semester_id"
                            :rules="formData.student_status == 102 ? 'required' : ''" v-slot="{ errors }">
                            <b-form-group :label="$t('graduation_semester')">
                                <semesters-selectbox v-model="formData.graduation_semester_id" :without-years="true"
                                                     :disabled="activeRole"
                                    :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="graduation_date" :rules="formData.student_status == 102 ? 'required' : ''"
                            v-slot="{ errors }">
                            <b-form-group :label="$t('graduation_date')">
                                <selectDate v-model="formData.graduation_date" :validationError="errors[0]" v-if="!activeRole"/>
                                <b-form-input
                                    v-model="formData.graduation_date"
                                    :disabled="activeRole"
                                    v-else
                                ></b-form-input>

                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="graduation_decision_number" v-slot="{ errors }">
                            <b-form-group :label="$t('graduation_decision_number')">
                                <b-form-input v-model="formData.graduation_decision_number"
                                              :disabled="activeRole"
                                    :class="errors[0] ? 'is-invalid' : ''" />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="cgpa" v-slot="{ errors }" rules="required">
                            <b-form-group :label="$t('cgpa')">
                                <b-form-input type="number" step=".01" v-model="formData.cgpa"
                                    :class="errors[0] ? 'is-invalid' : ''" />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="graduate_detail_cgpa" v-slot="{ errors }" rules="required">
                            <b-form-group :label="$t('Graduation_GPA')">
                                <b-form-input type="number" step=".01" v-model="formData.graduate_detail_cgpa"
                                              :class="errors[0] ? 'is-invalid' : ''" />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                </b-col>
                <b-col md="4" class="flex-column d-flex">
                    <div class="mb-2">
                        <h6 class="mb-0">{{ $t('diploma_information') }}</h6>
                    </div>
                    <div class="border rounded equal-height p-4 mb-4">
                        <ValidationProvider name="diploma_status" v-slot="{ errors }">
                            <b-form-group :label="$t('diploma_status')">
                                <parameter-selectbox v-model="formData.diploma_status" :disabled="activeRole" code="diploma_statuses"
                                    :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="diploma_status" v-slot="{ errors }">
                            <b-form-group :label="$t('diploma_supplement_status')">
                                <parameter-selectbox v-model="formData.diploma_supplement_status"
                                                     :disabled="activeRole"
                                    code="diploma_supplement_statuses" :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="diploma_date" v-slot="{ errors }">
                            <b-form-group :label="$t('diploma_date')">
                                <selectDate v-model="formData.diploma_date" :validation-error="errors[0]" v-if="!activeRole"/>
                                <b-form-input
                                    v-model="formData.diploma_date"
                                    :disabled="activeRole"
                                    v-else
                                ></b-form-input>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="diploma_number" :rules="formData.student_status === '102' ? 'required' : ''" v-slot="{ errors }">
                            <b-form-group :label="$t('diploma_number')">
                                <b-input-group class="mt-3">
                                    <template #append v-if="!activeRole">
                                        <b-input-group-text @click="getDiplomaNumber" style="padding: 6px;"><i
                                                class="ri-download-fill" style="font-size: 18px;"
                                                v-b-tooltip.hover.top="$t('diploma_number_from_system')"></i></b-input-group-text>
                                    </template>
                                    <b-form-input :readonly="DNreadonly" v-model="formData.diploma_number"
                                                  :disabled="activeRole"
                                        :class="errors[0] ? 'is-invalid' : ''" />

                                </b-input-group>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                </b-col>
                <b-col md="4" class="flex-column d-flex">
                    <div class="mb-2">
                        <h6 class="mb-0">{{ $t('leaving_information') }}</h6>
                    </div>
                    <div class="border rounded equal-height p-4 mb-4">
                        <ValidationProvider name="leaving_reason" :rules="formData.student_status == 102 ? 'required' : ''"
                            v-slot="{ errors }">
                            <b-form-group :label="$t('reason_for_leaving')">
                                <parameter-selectbox v-model="formData.leaving_reason"  :disabled="activeRole" code="leaving_reasons"
                                    :validateError="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="leaving_semester_id" :rules="hasLeavingSemesterReq"
                            v-slot="{ errors }">
                            <b-form-group :label="$t('leaving_semester')">
                                <semesters-selectbox v-model="formData.leaving_semester_id" :disabled="activeRole" />
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="leaving_date" :rules="formData.student_status == 102 ? 'required' : ''"
                            v-slot="{ errors }">
                            <b-form-group :label="$t('leaving_date')">
                                <selectDate v-model="formData.leaving_date" :validationError="errors[0]" v-if="!activeRole"/>
                                <b-form-input
                                    v-model="formData.leaving_date"
                                    :disabled="activeRole"
                                    v-else
                                ></b-form-input>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                </b-col>

            </b-row>
            <div class="d-flex justify-content-center">
                <b-button variant="primary" @click="updateForm">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Components
import CurriculumSelectbox from '@/components/interactive-fields/CurriculumSelectbox';
import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
import SelectDate from '@/components/interactive-fields/SelectDate';
import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox';
import StaffAutoComplete from '@/components/interactive-fields/StaffAutoComplete';
import YesNoSelectbox from '@/components/interactive-fields/YesNoSelectbox';

// Services
import StudentProgramService from '@/services/StudentProgramService'

// Other
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ConstantService from "@/services/ConstantService";

export default {
    name: "StudentsUniversityInformationUpdate",
    components: {
        CurriculumSelectbox,
        ParameterSelectbox,
        SelectDate,
        SemestersSelectbox,
        StaffAutoComplete,
        YesNoSelectbox,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            activeRol:null,
            formId: null,
            formData: {},
            formLoading: false,
            DNreadonly: false,
            advisorObject: {},
            activeRoleId: 0
        }
    },
    props: {
        studentProgram: {
            type: Object,
            default: null
        }
    },
    computed: {
        hasLeavingSemesterReq() {
            if (["102", "104", "105"].includes(this.formData.student_status)) {
                return "required"
            }
            return ""
        },
        activeRole(){
            if (this.activeRol=='PA'){
                return true
            }else{
                return false
            }
        }
    },
    mounted() {
        this.getActiveRole()
    },
    methods: {
       async getActiveRole(){
             let role = await this.$store.getters['auth/getUser']
             this.activeRol=role.active_role.name
        },
        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.formId) {
                StudentProgramService.update(this.formId, this.formData)
                    .then(response => {
                        this.$emit('updateFormSuccess', true);
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate)
                    })
            }
        },
        async getDiplomaNumber() {
            if (!this.haveDiplomaNumber) {
                const response = await ConstantService.getValuePrivate('last_diploma_number');
                this.formData.diploma_number = Number(response.data.data) + 1
            }

        }
    },
    created() {
        this.formId = this.studentProgram.id;
        this.formData = {
            // 1
            registration_date: this.studentProgram.registration_date,
            registration_semester_id: this.studentProgram.registration_semester_id,
            registration_type: this.studentProgram.registration_type,
            advisor_id: this.studentProgram.advisor_id,
            prep_status: this.studentProgram.prep_status,
            student_status: this.studentProgram.student_status,
            semester_status: this.studentProgram.semester_status,
            class: this.studentProgram.class,
            curriculum_id: this.studentProgram.curriculum_id,
            irregular: this.studentProgram.irregular,
            yoksis_unit_id:this.studentProgram.yoksis_unit_id,

            // 2
            graduation_semester_id: this.studentProgram.graduation_semester_id,
            graduation_date: this.studentProgram.graduation_date,
            graduation_decision_number: this.studentProgram.graduation_decision_number,

            // 3
            leaving_reason: this.studentProgram.leaving_reason,
            leaving_date: this.studentProgram.leaving_date,
            leaving_semester_id: this.studentProgram.leaving_semester_id,

            // 5
            diplama_status: this.studentProgram.diplama_status,
            diploma_supplement_status: this.studentProgram.diploma_supplement_status,
            diploma_number: this.studentProgram.diploma_number,
            diploma_date: this.studentProgram.diploma_date,
            cgpa: this.studentProgram.cgpa,
            graduate_detail_cgpa: this.studentProgram.graduate_detail_cgpa,

        }

        if (this.studentProgram.advisor_id) {
            this.advisorObject = {
                value: this.studentProgram.advisor_id,
                text: this.studentProgram.advisor_name
            }
        }

        let role_id = this.$store.getters['auth/getUser'].active_role.id

        if ((this.formData.student_status === '102' && this.formData.diploma_number) && ![1,27].includes(role_id)) {
            this.DNreadonly = true
        }


    }
}
</script>

